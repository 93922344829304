import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:users',
    },
    users: [],
    userGroups(rootState) {
      return [
        {
          id: 6,
          name: rootState.i18n.t('userGroup.admin'),
        },
        {
          id: 5,
          name: rootState.i18n.t('userGroup.manager'),
        },
      ];
    },
  },
  getters: {
    getUsersApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getUsers(state) {
      return state.users;
    },
    getUserGroups(state, getters, rootState) {
      return state.userGroups(rootState);
    },
    getUser: (state) => (id) => {
      const find = state.users.find((item) => item.ID === id);
      return find || false;
    },
  },
  mutations: {
    addUser(state, { item }) {
      state.users.push(item);
    },
    setUsers(state, { items }) {
      state.users = items;
    },
    updateUser(state, { id, item }) {
      const index = state.users.findIndex((user) => user.ID === id);

      if (index !== false) {
        const user = item;
        user.ID = id;

        state.users.splice(index, 1, item);
      }
    },
  },
  actions: {
    getUsers({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getUsersApiUrl);
        url.searchParams.set('action', 'getUsers');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getUser({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getUsersApiUrl);
        url.searchParams.set('action', 'getUser');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addUser({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getUsersApiUrl);
        url.searchParams.set('action', 'addUser');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateUser({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getUsersApiUrl);
        url.searchParams.set('action', 'updateUser');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    setUsers({ commit }, { items }) {
      commit('setUsers', { items });
    },
  },
  modules: {
  },
};
